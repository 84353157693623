import React from "react";
import {
    may
  } from './me.module.css'

import { useEffect } from 'react';
import gsap from "gsap";

function Me() {

  useEffect(()=> {
    var tl = gsap.timeline({repeat:-1});
    tl.to(".eyes", {duration: 0.3, scaleY:0.15, transformOrigin: "23%", ease: "circ.inOutinOut"}, "+=4")
      .to(".eyes", {duration: 0.2, scaleY:1, transformOrigin: "23%", ease: "circ.inOutinOut"});
  }, []);

  return (
    <svg 
    className={may} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -5 485 440">
      <path
        stroke="#AEAEAE"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M50 390h370"
        vectorEffect="non-scaling-stroke"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M287.976 88.6c-1.965 12.974 21.815 34.513 27.209 46.483 2.066 4.58 2.876 9.682 2.085 14.885 0 0-1.425 14.671-13.727 20.433"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M230.601 27.922c33.112-6.421 57.997 8.527 65.727 29.16 3.768 10.054 5.024 23.877-6.907 38.957"
      ></path>
      <path
        fill="url(#paint0_radial)"
        d="M263.675 12.786c0 7.81-10.681 16.022-23.862 16.022s-23.862-8.212-23.862-16.022c0-7.81 14.443-12.88 23.862-6.597 6.279-6.284 23.862-1.213 23.862 6.597z"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M233.691 25.409c-10.524-1.514-11.981-7.301-11.46-13.565.326-3.902 4.395-7.54 16.326-5.655"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M213.301 20.552s35.711 8.746 43.467.088c3.384-3.776 6.907-28.274-16.955-14.451m-57.827 94.933s-40.132 37.643-25.13 58.183"
      ></path>
      <path
        fill="url(#paint1_radial)"
        d="M308.993 129.076c-7.12-11.021-17.525-23.707-17.525-36.832 0 0 4.747-13.201 4.238-27.621-.892-25.008-26.097-41.784-59.027-41.784-32.213 0-57.356 16.99-60.282 41.155-1.256 10.38 5.488 30.129 5.488 30.129 0 12.591-10.072 24.799-16.634 35.537-2.499 4.085-3.925 8.797-3.925 13.817 0 15.614 13.777 28.275 30.769 28.275.817 0 1.633-.032 2.431-.088a65.047 65.047 0 0121.733 2.117c6.386 1.734 13.256 2.683 20.42 2.683 7.259 0 14.223-.974 20.685-2.752 7.083-1.954 14.443-2.871 21.758-2.13a33.85 33.85 0 003.404.17c16.992 0 30.769-12.661 30.769-28.275-.013-5.259-1.576-10.185-4.302-14.401z"
      ></path>
      <path
        fill="#3C3C3B"
        d="M309.972 310.222c0 40.257-32.609 45.252-72.841 45.252-40.227 0-72.842-5.002-72.842-45.252s32.615-88.594 72.842-88.594c40.226 0 72.841 48.337 72.841 88.594z"
      ></path>
      <path
        fill="#3C3C3B"
        d="M163.832 214.164c0-40.257 32.609-45.252 72.842-45.252 40.226 0 72.841 5.001 72.841 45.252 0 40.25-32.615 88.593-72.841 88.593-40.227 0-72.842-48.343-72.842-88.593z"
      ></path>
      <path
        fill="#C2BAD4"
        d="M195.047 290.681c0 1.985 11.843 3.807 13.664 4.586a70.85 70.85 0 0027.956 5.718 70.806 70.806 0 0027.956-5.718c1.827-.785 18.688-2.601 18.688-4.586 0 0-5.828-47.615-5.828-63.323 0-21.193-9.871-48.267-9.871-48.267 0-1.986-1.168-3.808-2.989-4.587a70.85 70.85 0 00-27.956-5.718c-9.928 0-19.378 2.036-27.956 5.718-1.827.785-2.989 2.601-2.989 4.587 0 0-9.865 30.046-9.865 48.896 0 16.349-.81 62.694-.81 62.694z"
      ></path>
      <path
        fill="#9297BE"
        d="M236.673 168.78c-6.656 0-13.099.923-19.209 2.632 2.776 6.309 11.894 10.707 19.209 10.707 7.793 0 17.639-3.726 20.094-10.681-4.54-.911-15.284-2.658-20.094-2.658z"
      ></path>
      <path
        fill="#fff"
        d="M220.328 174.384l4.917-3.053c.514-.321.043-1.137-.478-.817l-4.917 3.054c-.514.326-.043 1.143.478.816zm4.119 3.689c.929-1.1 1.859-2.199 2.788-3.305.163-.195.189-.478 0-.666-.169-.17-.502-.195-.665 0-.93 1.099-1.859 2.199-2.789 3.305-.163.194-.188.477 0 .666.17.169.503.194.666 0zm4.509 2.676a29.05 29.05 0 002.681-6.201c.176-.585-.735-.83-.91-.252a27.264 27.264 0 01-2.588 5.976c-.301.528.515 1.005.817.477zm5.042-4.524c-.496 1.892-.998 3.789-1.495 5.68-.157.585.754.836.911.252.496-1.892.998-3.789 1.495-5.68.15-.591-.76-.842-.911-.252zm3.328.132c.138 1.936.283 3.871.421 5.812.044.604.986.604.942 0-.138-1.935-.283-3.87-.421-5.812-.044-.603-.986-.609-.942 0zm3.674.107a1572.17 1572.17 0 001.796 5.441c.188.572 1.099.327.91-.251-.596-1.816-1.193-3.625-1.796-5.441-.194-.572-1.105-.327-.91.251zm3.604-1.269l3.529 5.85c.314.521 1.13.044.816-.478l-3.529-5.85c-.314-.515-1.13-.044-.816.478zm4.389-1.47a31.43 31.43 0 004.314 4.115c.201.157.471.195.666 0 .163-.163.201-.509 0-.666a31.372 31.372 0 01-4.314-4.115c-.17-.195-.496-.17-.666 0-.194.188-.169.471 0 .666zm1.426-2.765c1.426.974 2.851 1.942 4.283 2.915.207.145.521.038.646-.169.139-.233.044-.503-.169-.647-1.426-.974-2.851-1.942-4.283-2.916-.213-.144-.521-.037-.646.17-.139.232-.044.503.169.647z"
        opacity="0.32"
      ></path>
      <path
        fill="#401B0D"
        d="M194.601 84.1l-2.512 23.249c-7.535 21.363-10.75 32.032-9.419 39.584 1.884 10.682 9.419 16.336 12.559 18.221 13.708 8.231 27.008-.791 45.212-1.885l30.882-36.788-76.722-42.38z"
        opacity="0.3"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M194.639 85.395c5.551 25.428-13.187 23.292-21.043 47.344a28.312 28.312 0 00-.734 14.86s2.436 14.489 14.041 19.648"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M233.357 202.508l1.256 1.401a.286.286 0 01-.012.402.273.273 0 01-.396-.025l-1.262-1.407a.286.286 0 01.019-.402.28.28 0 01.395.031zm-2.185-2.507l1.231 1.426a.288.288 0 01-.025.403.28.28 0 01-.396-.032l-1.237-1.426c-.1-.12-.088-.302.026-.396.119-.113.295-.094.401.025z"
      ></path>
      <path
        fill="#FAC354"
        d="M232.221 202.062l.483.547a.284.284 0 00.389.031l.032-.031a.278.278 0 00.019-.39l-.484-.547a.278.278 0 00-.389-.031l-.032.031a.283.283 0 00-.018.39z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M229.106 197.544l1.206 1.446c.1.119.088.301-.031.395a.28.28 0 01-.396-.037l-1.206-1.452a.28.28 0 01.032-.396.276.276 0 01.395.044z"
      ></path>
      <path
        fill="#FAC354"
        d="M230.117 199.618l.471.553a.279.279 0 00.389.038l.038-.032c.113-.1.126-.27.025-.389l-.471-.553a.277.277 0 00-.389-.038l-.038.031a.291.291 0 00-.025.39zm-2.053-2.482l.464.565c.101.12.271.139.39.044l.038-.031a.278.278 0 00.031-.39l-.459-.565a.278.278 0 00-.389-.044l-.038.031a.28.28 0 00-.037.39z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M226.871 194.755l1.168 1.476a.28.28 0 01-.038.396.283.283 0 01-.396-.05l-1.174-1.477a.28.28 0 01.044-.396c.119-.1.295-.075.396.051zm-2.016-2.652l1.13 1.502a.28.28 0 01-.05.396.283.283 0 01-.396-.057l-1.136-1.514a.282.282 0 01.056-.396.295.295 0 01.396.069z"
      ></path>
      <path
        fill="#FAC354"
        d="M225.765 194.233l.446.578c.095.12.27.145.39.05l.037-.025a.27.27 0 00.044-.383l-.439-.578a.279.279 0 00-.383-.057l-.038.025a.274.274 0 00-.057.39z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M222.996 189.495c.358.516.709 1.031 1.08 1.54a.283.283 0 01-.063.396.29.29 0 01-.395-.069c-.371-.509-.729-1.031-1.087-1.546-.088-.132-.063-.308.069-.396a.284.284 0 01.396.075z"
      ></path>
      <path
        fill="#FAC354"
        d="M223.838 191.644l.427.591a.273.273 0 00.383.063l.037-.025a.27.27 0 00.05-.384l-.427-.59a.274.274 0 00-.383-.069l-.037.025a.29.29 0 00-.05.389zm-1.84-2.645l.408.61a.275.275 0 00.383.075l.038-.025a.28.28 0 00.069-.383l-.402-.604a.281.281 0 00-.383-.081l-.038.025a.267.267 0 00-.075.383z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M221.049 186.505c.34.528.66 1.062 1.011 1.583a.28.28 0 01-.075.39c-.126.088-.301.044-.389-.088-.352-.522-.678-1.062-1.018-1.596a.279.279 0 01.088-.39c.126-.075.296-.031.383.101zm-1.651-2.878c.295.553.597 1.1.911 1.64a.276.276 0 01-.094.383.288.288 0 01-.39-.107 52.192 52.192 0 01-.916-1.658.276.276 0 11.489-.258z"
      ></path>
      <path
        fill="#FAC354"
        d="M220.014 185.857l.376.629a.277.277 0 00.377.1l.038-.025a.281.281 0 00.088-.383l-.371-.622a.277.277 0 00-.377-.101l-.037.025a.274.274 0 00-.094.377z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M218.048 180.743c.22.584.503 1.137.754 1.709.063.145.012.308-.126.377a.277.277 0 01-.377-.132c-.257-.578-.546-1.143-.766-1.74-.056-.151.007-.314.151-.371a.28.28 0 01.364.157z"
      ></path>
      <path
        fill="#FAC354"
        d="M218.469 183.018l.333.653a.276.276 0 00.37.126l.038-.019a.278.278 0 00.113-.371l-.327-.641a.273.273 0 00-.37-.125l-.044.018c-.132.051-.182.22-.113.359zm-1.287-2.985c.075.239.175.459.263.691.057.145.22.214.358.157l.044-.019a.278.278 0 00.151-.358c-.088-.226-.188-.44-.258-.672a.268.268 0 00-.345-.17l-.044.013a.28.28 0 00-.169.358z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M217.031 177.375c.044.302.113.603.194.899.082.295.145.597.251.892a.276.276 0 01-.175.352.293.293 0 01-.358-.189c-.113-.301-.176-.615-.258-.923a6.798 6.798 0 01-.207-.943c-.025-.157.075-.308.226-.333.151-.025.301.094.327.245zm-.088-3.242c-.057.61-.094 1.238-.069 1.847a.281.281 0 01-.264.296.282.282 0 01-.295-.277c-.025-.647.013-1.275.069-1.916a.278.278 0 01.308-.258.29.29 0 01.251.308z"
      ></path>
      <path
        fill="#FAC354"
        d="M216.346 176.364c.013.251.025.502.082.741.025.157.169.264.314.239l.044-.007a.272.272 0 00.226-.314c-.05-.232-.063-.465-.076-.697a.278.278 0 00-.288-.258h-.044a.27.27 0 00-.258.296z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M217.684 171.105c-.226.559-.433 1.137-.54 1.74a.284.284 0 01-.333.22c-.15-.025-.245-.176-.213-.339.119-.635.333-1.251.578-1.854.069-.151.244-.214.376-.144a.3.3 0 01.132.377z"
      ></path>
      <path
        fill="#FAC354"
        d="M216.529 173.096l-.107.735a.273.273 0 00.245.308l.044.007a.274.274 0 00.295-.245l.1-.704a.275.275 0 00-.213-.321l-.044-.006a.272.272 0 00-.32.226zm1.111-3.104c-.138.208-.245.44-.358.666a.267.267 0 00.126.371l.044.019a.292.292 0 00.364-.132c.107-.207.201-.421.333-.616a.283.283 0 00-.088-.377l-.038-.025c-.125-.082-.295-.044-.383.094z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M219.781 168.434a6.116 6.116 0 00-1.275 1.27.292.292 0 01-.395.056c-.126-.088-.151-.264-.05-.396.389-.528.86-.993 1.381-1.376.132-.1.308-.075.396.05a.294.294 0 01-.057.396zm2.888-1.414c-.59.176-1.161.403-1.708.673a.285.285 0 01-.376-.113c-.076-.132-.019-.308.125-.384a11.104 11.104 0 011.796-.71.286.286 0 01.358.183.289.289 0 01-.195.351z"
      ></path>
      <path
        fill="#FAC354"
        d="M220.39 167.379c-.219.119-.427.264-.64.396-.132.094-.163.27-.069.389l.025.038a.278.278 0 00.377.063c.201-.12.389-.258.596-.365a.27.27 0 00.113-.364l-.019-.038a.277.277 0 00-.383-.119z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M225.771 166.392c-.615.076-1.23.176-1.833.308a.29.29 0 01-.339-.213.276.276 0 01.22-.334 21.553 21.553 0 011.883-.314.285.285 0 01.321.239.292.292 0 01-.252.314z"
      ></path>
      <path
        fill="#FAC354"
        d="M223.467 166.235c-.238.063-.483.113-.716.189a.28.28 0 00-.194.345l.012.044a.27.27 0 00.339.182c.226-.069.459-.119.691-.182a.28.28 0 00.207-.327l-.012-.044a.263.263 0 00-.327-.207zm3.209-.496l-.729.075a.277.277 0 00-.245.308l.007.044a.271.271 0 00.307.233l.716-.076a.277.277 0 00.251-.295l-.006-.044a.267.267 0 00-.301-.245z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M229.314 166.122c-.622.025-1.25.056-1.872.1a.281.281 0 01-.307-.257.285.285 0 01.263-.302c.628-.05 1.263-.082 1.891-.107a.282.282 0 01.295.27.28.28 0 01-.27.296zm3.321-.063l-1.877.019a.282.282 0 01-.289-.277.273.273 0 01.283-.282l1.89-.019c.157 0 .288.126.288.276a.292.292 0 01-.295.283z"
      ></path>
      <path
        fill="#FAC354"
        d="M230.387 165.538l-.728.018a.275.275 0 00-.27.283v.044c.006.151.132.264.289.264l.722-.019c.151-.006.276-.125.27-.276v-.044a.27.27 0 00-.283-.27z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M235.85 166.059h-1.883a.28.28 0 01-.283-.276c0-.158.126-.277.283-.277h1.883a.28.28 0 01.283.277c.006.15-.126.276-.283.276z"
      ></path>
      <path
        fill="#FAC354"
        d="M233.609 165.506h-.729a.275.275 0 00-.276.277v.044c0 .15.126.27.283.27h.728a.27.27 0 00.276-.27v-.044a.289.289 0 00-.282-.277zm3.215.006h-.728a.27.27 0 00-.277.271v.044c0 .15.126.27.277.27h.728a.27.27 0 00.276-.27v-.044a.274.274 0 00-.276-.271z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M239.429 166.059h-1.883a.286.286 0 01-.289-.276c0-.157.125-.277.282-.277h1.884a.28.28 0 01.283.277c.012.15-.113.276-.277.276zm3.329.025l-1.878-.019a.28.28 0 01-.282-.282c0-.157.132-.277.289-.277l1.89.019c.157 0 .282.132.282.283a.3.3 0 01-.301.276z"
      ></path>
      <path
        fill="#FAC354"
        d="M240.522 165.506h-.728a.27.27 0 00-.276.27v.044c0 .151.125.271.276.271h.728c.151 0 .277-.12.283-.271v-.044a.288.288 0 00-.283-.27z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M245.96 166.204c-.621-.038-1.249-.063-1.871-.088a.281.281 0 01-.276-.289.285.285 0 01.295-.27c.628.018 1.262.05 1.89.087a.292.292 0 01.27.302c-.006.157-.151.27-.308.258z"
      ></path>
      <path
        fill="#FAC354"
        d="M243.744 165.556l-.729-.019a.276.276 0 00-.282.271v.044c0 .15.119.276.27.276l.728.019a.279.279 0 00.289-.264v-.044a.276.276 0 00-.276-.283zm3.221.164l-.729-.05a.266.266 0 00-.295.251v.044a.274.274 0 00.258.289l.722.05a.274.274 0 00.301-.245l.006-.044a.292.292 0 00-.263-.295z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M249.489 166.581a35.625 35.625 0 00-1.852-.239.282.282 0 01-.258-.302c.013-.151.157-.264.314-.251.628.063 1.256.144 1.884.245a.289.289 0 01.239.327c-.025.144-.17.238-.327.22zm3.184.772a17.222 17.222 0 00-1.783-.502.276.276 0 01-.214-.333.277.277 0 01.34-.208c.621.139 1.237.321 1.839.522a.29.29 0 01.176.364.27.27 0 01-.358.157z"
      ></path>
      <path
        fill="#FAC354"
        d="M250.663 166.235c-.238-.056-.483-.094-.722-.138a.273.273 0 00-.32.22l-.006.044a.28.28 0 00.226.32c.232.044.471.082.703.138a.27.27 0 00.327-.207l.012-.044a.29.29 0 00-.22-.333z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M255.429 168.805a8.172 8.172 0 00-1.557-.949c-.138-.063-.201-.232-.138-.371a.286.286 0 01.383-.132c.578.283 1.143.61 1.651 1.012.132.101.157.277.057.396a.288.288 0 01-.396.044z"
      ></path>
      <path
        fill="#FAC354"
        d="M253.778 167.203c-.226-.113-.458-.189-.691-.283a.272.272 0 00-.358.157l-.012.044a.28.28 0 00.163.352c.22.088.446.163.659.27.139.063.296 0 .365-.132l.018-.044c.063-.132 0-.295-.144-.364zm2.75 1.828c-.188-.17-.364-.352-.558-.503a.28.28 0 00-.396.038l-.025.038a.283.283 0 00.044.383c.188.138.345.314.521.465.107.101.27.113.383.006l.031-.031c.107-.101.12-.289 0-.396z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M257.552 171.475c-.232-.559-.584-1.062-.948-1.539a.278.278 0 01.025-.39c.113-.1.295-.088.408.038.396.515.772 1.062 1.024 1.659.069.151.012.314-.132.377a.306.306 0 01-.377-.145zm.772 3.123c-.056-.609-.138-1.219-.301-1.81a.29.29 0 01.195-.345c.144-.044.301.05.345.207.176.622.264 1.263.32 1.904.019.163-.094.302-.251.308a.293.293 0 01-.308-.264z"
      ></path>
      <path
        fill="#FAC354"
        d="M258.456 172.292c-.075-.239-.169-.471-.251-.71a.27.27 0 00-.364-.151l-.044.019a.274.274 0 00-.138.358l.232.66a.272.272 0 00.333.189l.044-.013a.28.28 0 00.188-.352z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M258.161 177.727l.145-.911c.031-.302.037-.609.062-.911a.3.3 0 01.283-.283c.151 0 .289.126.276.289-.019.321-.031.647-.063.968l-.15.955a.284.284 0 01-.333.226.283.283 0 01-.22-.333z"
      ></path>
      <path
        fill="#FAC354"
        d="M258.921 175.541l-.025-.742c-.013-.157-.151-.276-.301-.257l-.044.006a.268.268 0 00-.245.295l.025.71c.006.151.125.27.276.277h.044a.273.273 0 00.27-.289zm-.446 3.254l.176-.722c.044-.151-.063-.302-.208-.333l-.044-.007a.294.294 0 00-.326.214l-.17.691a.287.287 0 00.195.339l.044.013a.263.263 0 00.333-.195z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M257.125 181.095c.22-.578.446-1.163.622-1.753a.283.283 0 01.351-.189.278.278 0 01.183.352 24.616 24.616 0 01-.635 1.797.278.278 0 01-.364.164.291.291 0 01-.157-.371zm-1.381 3.003c.289-.553.552-1.118.816-1.684.063-.144.226-.207.371-.144a.29.29 0 01.138.377c-.27.572-.534 1.15-.829 1.709a.291.291 0 01-.383.126c-.138-.07-.182-.246-.113-.384z"
      ></path>
      <path
        fill="#FAC354"
        d="M257.206 182.308l.289-.673a.274.274 0 00-.144-.364l-.044-.019a.28.28 0 00-.358.151l-.289.659a.276.276 0 00.138.365l.044.019c.145.075.302.006.364-.138z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M254.199 186.901c.326-.534.621-1.087.929-1.628a.288.288 0 01.383-.119.274.274 0 01.107.383c-.314.547-.609 1.106-.936 1.646a.28.28 0 11-.483-.282z"
      ></path>
      <path
        fill="#FAC354"
        d="M255.781 185.217l.339-.648a.275.275 0 00-.113-.37l-.037-.019a.28.28 0 00-.371.119l-.333.641a.283.283 0 00.107.377l.038.019a.273.273 0 00.37-.119zm-1.607 2.802l.37-.628a.269.269 0 00-.094-.377l-.038-.026a.277.277 0 00-.376.101l-.371.622a.267.267 0 00.088.377l.038.025a.276.276 0 00.383-.094z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M252.309 189.929c.345-.521.684-1.049 1.011-1.583a.284.284 0 01.389-.094c.132.081.17.257.088.389a89.466 89.466 0 01-1.017 1.596.285.285 0 01-.389.088.29.29 0 01-.082-.396zm-1.865 2.746c.358-.515.722-1.024 1.068-1.546a.283.283 0 01.389-.081.286.286 0 01.069.395c-.352.522-.71 1.043-1.074 1.559a.276.276 0 01-.389.075c-.126-.088-.151-.27-.063-.402z"
      ></path>
      <path
        fill="#FAC354"
        d="M252.171 191.142l.408-.61a.279.279 0 00-.076-.383l-.037-.025a.273.273 0 00-.377.082l-.402.603a.273.273 0 00.069.383l.038.025c.119.088.289.05.377-.075z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M248.56 195.276l1.111-1.52a.29.29 0 01.396-.069c.126.088.151.263.056.395l-1.117 1.527a.283.283 0 01-.396.063.287.287 0 01-.05-.396z"
      ></path>
      <path
        fill="#FAC354"
        d="M250.331 193.787l.421-.597c.088-.125.062-.301-.063-.383l-.038-.025a.273.273 0 00-.383.069l-.421.59a.278.278 0 00.057.384l.038.025a.274.274 0 00.389-.063zm-1.909 2.601l.439-.584a.278.278 0 00-.05-.39l-.037-.025a.273.273 0 00-.383.063l-.44.584a.272.272 0 00.05.384l.038.025a.278.278 0 00.383-.057z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M246.393 198.122l1.149-1.489a.28.28 0 11.446.34l-1.155 1.495a.28.28 0 01-.396.05.287.287 0 01-.044-.396zm-2.078 2.595l1.181-1.464a.282.282 0 01.395-.05.28.28 0 01.038.396l-1.187 1.47a.279.279 0 01-.395.044c-.12-.1-.132-.27-.032-.396z"
      ></path>
      <path
        fill="#FAC354"
        d="M246.155 199.316l.452-.571a.28.28 0 00-.044-.39l-.038-.025a.28.28 0 00-.389.05l-.452.572a.284.284 0 00.038.39l.037.031a.294.294 0 00.396-.057z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M242.268 203.193l1.206-1.445c.1-.12.276-.138.395-.044.12.1.132.276.032.396l-1.212 1.445c-.101.119-.283.138-.396.038a.272.272 0 01-.025-.39z"
      ></path>
      <path
        fill="#FAC354"
        d="M244.127 201.823l.464-.565a.277.277 0 00-.031-.39l-.038-.031a.278.278 0 00-.389.044l-.458.565c-.094.12-.082.296.031.39l.038.031a.27.27 0 00.383-.044zm-2.073 2.463l.471-.553a.276.276 0 00-.025-.389l-.037-.032a.28.28 0 00-.39.038l-.471.553a.278.278 0 00.025.39l.038.031a.277.277 0 00.389-.038z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M240.101 206.127l1.262-1.446.019-.025.019-.019.025-.012.025-.019.025-.006.025-.013.031-.006.026-.006h.056l.025.006.025.006.025.013.026.012.025.013.025.019.019.019.018.018.013.026.013.025.012.025.006.025.007.025.006.025v.032l-.006.025-.007.031-.006.025-.006.025-.019.026-.013.025-.018.025-1.263 1.451-.025.019-.019.019-.025.019-.025.012-.025.013-.025.006-.031.007-.025.006h-.057l-.025-.006-.025-.007-.025-.006-.025-.013-.026-.018-.018-.019-.026-.019-.018-.019-.013-.025-.013-.025-.012-.025-.006-.026-.007-.025v-.081l.007-.032.012-.025.006-.025.013-.025.019-.025.019-.025zm-2.28 2.463l.333-.321.163-.163.082-.082.044-.038h-.006l.006-.006.006-.006.007-.013.018-.019.647-.71.019-.025.025-.019.025-.012.025-.013.026-.012.025-.007.025-.006.025-.006h.056l.026.006.031.006.025.013.025.013.019.012.025.019.019.019.019.025.012.019.013.025.012.025.007.025.006.032v.081l-.006.025-.013.026-.006.031-.013.025-.018.019-.019.025-.647.716-.327.352-.081.088-.082.088-.163.157-.025.019-.019.019-.025.013-.025.012-.026.006-.031.007-.025.006h-.025l-.032-.006h-.025l-.025-.007-.025-.012-.025-.013-.025-.012-.025-.019-.019-.019-.025-.025-.019-.025-.013-.025-.012-.026-.013-.025-.006-.031v-.025l-.006-.032.006-.025v-.025l.006-.025.007-.031.012-.026.019-.018.013-.026.018-.018z"
      ></path>
      <path
        fill="#FAC354"
        d="M239.774 207.327l.496-.56.019-.018.013-.026.018-.025.007-.025.006-.025.006-.031.007-.025v-.026l-.007-.025v-.031l-.012-.025-.007-.025-.012-.019-.013-.025-.018-.019-.019-.019-.038-.032-.019-.018-.025-.013-.025-.013-.025-.012-.025-.006h-.025l-.026-.007h-.031l-.025.007-.025.006-.025.006-.025.013-.026.012-.025.019-.018.019-.019.019-.496.553-.019.025-.013.025-.012.019-.013.031-.006.025-.006.026-.007.025v.025l.007.031v.025l.012.026.006.025.013.025.013.019.018.025.019.019.038.031.019.013.025.019.025.012.025.006.025.007.025.006h.082l.025-.006.025-.013.025-.006.025-.013.026-.019.018-.018.019-.019z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M235.573 210.776l1.319-1.282.019-.019.025-.018.025-.013.025-.006.025-.013h.025l.032-.006h.025l.031.006h.026l.025.013.031.006.025.013.025.018.025.019.019.025.025.019.013.025.019.026.012.025.007.025.006.025.006.031v.051l-.006.031-.006.025-.007.025-.012.025-.013.026-.019.018-.019.026L236 211.21l-.025.018-.019.019-.025.013-.025.012-.025.007-.031.006-.025.006h-.057l-.025-.006-.025-.006-.025-.013-.025-.012-.026-.013-.025-.019-.019-.019-.025-.025-.025-.031-.012-.025-.013-.032-.012-.025-.007-.025-.006-.031-.006-.032v-.025l.006-.025.006-.032.007-.025.012-.025.013-.025.019-.019.018-.025z"
      ></path>
      <path
        fill="#FAC354"
        d="M237.552 209.67l.508-.502.019-.019.019-.025.012-.019.013-.025.006-.025.007-.026v-.031l.006-.025-.006-.025v-.025l-.007-.025-.012-.032-.013-.025-.012-.019-.019-.025-.019-.025-.032-.032-.025-.018-.018-.019-.026-.013-.025-.012-.025-.013-.025-.006-.031-.007h-.076l-.031.007-.025.006-.025.013-.019.012-.025.013-.019.019-.515.496-.019.025-.012.019-.013.025-.013.025-.012.025v.025l-.006.026v.056l.006.025.006.025.013.026.012.031.013.025.019.019.019.025.037.038.019.019.025.018.025.013.025.013.026.012.025.006h.025l.025.007h.031l.025-.007.026-.006.025-.006.025-.013.025-.012.019-.013.019-.019zm-2.236 2.206l.509-.503.018-.019.019-.025.013-.019.012-.025.007-.025.006-.025v-.025l.006-.032-.006-.025v-.025l-.006-.025-.013-.025-.012-.025-.013-.026-.019-.025-.019-.018-.037-.044-.025-.019-.026-.019-.025-.019-.025-.013-.025-.012-.025-.006-.031-.007-.026-.006h-.031l-.025.006h-.025l-.025.013-.025.006-.026.013-.025.019-.018.018-.509.497-.019.019-.019.018-.012.026-.013.025-.006.025-.006.025v.088l.006.025.006.025.013.032.012.025.013.025.025.025.019.025.044.044.019.019.025.019.025.012.025.013.025.013.025.006.025.006h.057l.025-.006.025-.006.025-.007.025-.012.025-.013.019-.012.019-.019z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M240.578 209.978l1.357 1.244.025.019.012.025.019.019.013.025.006.032.006.025.006.025v.031l-.006.026v.031l-.006.025-.013.032-.012.025-.019.031-.019.031-.025.026-.019.018-.025.019-.025.019-.025.013-.025.012-.025.007-.026.006h-.081l-.032-.006-.025-.007-.025-.012-.025-.013-.025-.012-.019-.019-1.35-1.257-.019-.019-.019-.019-.012-.025-.013-.025-.006-.025-.006-.025-.007-.032v-.081l.007-.025.012-.025.007-.026.018-.025.013-.025.019-.025.025-.025.025-.019.025-.019.025-.012.025-.013.032-.006.025-.007.031-.006h.05l.032.006.025.007.025.012.025.013.025.012.019.019zm-2.386-2.224l.169.17.044.044.013.012h-.006l.006.007.019.018.088.076.339.308.678.622.019.019.019.018.012.026.013.025.012.025.007.025.006.025v.05l-.006.025-.007.026-.006.025-.013.025-.012.025-.019.025-.019.025-.019.019-.025.019-.025.013-.025.012-.025.013-.025.006-.025.006h-.076l-.025-.006-.025-.006-.025-.013-.025-.012-.025-.013-.019-.019-.672-.628-.672-.685-.019-.025-.019-.025-.012-.026-.013-.025-.006-.025-.006-.025-.007-.025v-.05l.007-.025.006-.026.006-.025.013-.025.012-.025.019-.025.019-.019.019-.019.025-.019.025-.012.025-.013.025-.006.025-.006h.076l.025.006.025.006.025.013.025.012.025.013.026.019.006.05z"
      ></path>
      <path
        fill="#FAC354"
        d="M239.398 209.689l.521.484.019.019.025.019.025.012.025.007.025.012h.025l.025.006h.032l.025-.006.025-.006.025-.006.025-.013.025-.013.025-.012.019-.019.025-.019.032-.038.025-.025.012-.025.019-.025.013-.031.006-.026.006-.025.007-.031v-.05l-.007-.025-.006-.032-.006-.025-.013-.019-.019-.025-.012-.025-.019-.019-.527-.478-.019-.018-.025-.013-.026-.013-.025-.012-.025-.006-.025-.007h-.056l-.026.007-.025.006-.031.006-.025.013-.025.012-.019.013-.025.019-.019.025-.038.038-.019.018-.012.026-.019.025-.006.025-.013.025-.006.025v.082l.006.025.006.025.007.025.012.025.013.025.019.019.019.019z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="0.561"
        d="M235.944 205.373l1.312 1.401.019.025.013.025.018.025.007.025.012.025.007.026v.031l.006.025-.006.025v.032l-.007.025-.012.025-.013.025-.012.025-.019.019-.019.019-.019.019-.025.019-.025.012-.025.013-.025.006-.025.006h-.082l-.031-.006-.026-.006-.025-.013-.025-.012-.025-.013-.025-.019-.019-.025-1.312-1.407-.019-.019-.019-.025-.012-.026-.013-.025-.006-.031-.007-.025-.006-.025v-.057l.006-.025.007-.025.012-.032.013-.018.012-.026.019-.025.019-.019.019-.018.025-.013.025-.013.025-.012.025-.006.032-.007h.075l.031.007.026.006.025.012.025.013.025.019.025.019.019.019z"
      ></path>
      <path
        fill="#FAC354"
        d="M237.106 207.421l.514.54.019.019.025.019.026.013.025.012.025.013.025.006.025.006h.082l.025-.006.025-.006.025-.013.025-.012.019-.019.025-.019.032-.032.018-.018.019-.019.013-.025.012-.026.007-.025.006-.025.006-.025v-.056l-.006-.026-.006-.025-.013-.025-.006-.025-.019-.025-.013-.025-.018-.019-.515-.54-.019-.019-.025-.019-.019-.013-.025-.012-.025-.013-.032-.006-.025-.006h-.075l-.032.006-.025.006-.025.013-.019.012-.025.019-.019.013-.037.031-.019.025-.013.019-.019.025-.006.025-.013.025-.006.026-.006.025v.05l.006.031.006.026.007.025.012.025.013.025.019.025.019.019zm-2.242-2.407l.509.553.018.019.019.019.025.013.025.012.026.013.025.006.025.006.031.007h.025l.025-.007h.026l.025-.012.025-.006.025-.013.025-.019.019-.012.038-.032.018-.025.013-.019.019-.025.012-.019.007-.025.006-.031.006-.026v-.05l-.006-.025v-.031l-.013-.025-.012-.026-.013-.025-.012-.025-.019-.019-.503-.546-.018-.019-.025-.019-.026-.019-.025-.012-.025-.007-.025-.012-.025-.007h-.082l-.025.007-.025.006-.025.006-.025.013-.019.019-.025.019-.032.031-.018.019-.019.019-.013.025-.012.025-.013.025-.006.025v.025l-.006.025v.026l.006.031.006.025.006.025.013.025.013.026.012.025.019.018z"
      ></path>
      <path
        fill="#E7C1A5"
        d="M221.897 144.413v24.065c0 5.366 6.619 9.72 14.776 9.72 8.163 0 14.776-4.348 14.776-9.72v-24.065h-29.552zm111.93 266.275s-20.276.575-27.011.231c-6.729-.343-26.847-2.981-26.847-2.981l1.147-22.453s20.111 2.638 26.846 2.982c6.73.344 27.011-.231 27.011-.231l-1.146 22.452z"
      ></path>
      <path
        fill="#7F5F51"
        d="M333.827 410.688s-20.276.575-27.011.231c-6.729-.343-26.847-2.981-26.847-2.981l1.147-22.453s20.111 2.638 26.846 2.982c6.73.344 27.011-.231 27.011-.231l-1.146 22.452z"
        opacity="0.3"
      ></path>
      <path
        fill="#3C3C3B"
        d="M371.48 406.376l-46.619 6.383c-8.427 1.232-.357-5.667-1.538-14.451l-6.16-8.03c-1.181-8.784 1.959-5.234 10.386-6.466l41.193-6.025c8.427-1.232 16.214 4.888 17.401 13.666.345 6.849-6.236 13.691-14.663 14.923z"
      ></path>
      <path
        stroke="#646262"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M375.699 402.32s7.57-16.678-1.538-24.691"
      ></path>
      <path
        fill="#A3A5AF"
        d="M279.483 409.203l-157.378-16.175c-23.322-1.973-46.889-22.663-44.998-46.973 1.89-24.31 26.511-34.922 57.325-25.284 64.728 20.238 154.644 47.705 154.644 47.705 15.121 3.952 19.968 3.054 20.552 11.398.628 8.941-2.528 22.197-5.611 28.619-2.173 4.542-3.077 3.217-24.534.71z"
      ></path>
      <g
        stroke="#45454B"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        opacity="0.36"
      >
        <path d="M296.722 410.117s.119-.428.326-1.213" opacity="0.36"></path>
        <path
          strokeDasharray="4.69 4.69"
          d="M297.695 406.359c2.198-9.016 7.064-32.132 2.826-45.415"
          opacity="0.36"
        ></path>
        <path
          d="M300.087 359.706c-.15-.396-.313-.785-.483-1.156"
          opacity="0.36"
        ></path>
      </g>
      <path
        fill="#FDDAC0"
        d="M130.3 396.185s20.22 1.609 26.964 1.609c6.738 0 26.964-1.609 26.964-1.609v-22.481s-20.22 1.608-26.964 1.608c-6.738 0-26.964-1.608-26.964-1.608v22.481z"
      ></path>
      <path
        fill="#7F5F51"
        d="M158.561 374.714c6.737 0 26.964-1.609 26.964-1.609v22.482s-16.829 1.338-24.905 1.57c-.778.025-2.486-22.443-2.059-22.443z"
        opacity="0.3"
      ></path>
      <path
        fill="#3C3C3B"
        d="M100.404 394.972l50.543 4.355c8.427 1.231-3.567-3.645-2.38-12.429l6.147-11.448c-2.951-1.771-9.224-1.878-16.741-1.363a8.571 8.571 0 01-2.932-.302c-5.382-1.489-22.336-7.301-30.568-9.016-15.071-3.142-17.545 6.497-18.732 15.281-.345 6.848 6.236 13.691 14.663 14.922z"
      ></path>
      <path
        fill="#B2B3C1"
        d="M189.291 409.038l161.9-15.507c23.335-1.835 47.015-22.387 45.256-46.703-1.758-24.316-26.317-35.08-57.186-25.623-66.669 28.367-159.259 40.712-159.259 40.712-15.146 3.858-19.981 2.94-20.616 11.272-.678 8.935 2.325 28.554 5.371 34.994 2.154 4.556 3.064 3.23 24.534.855z"
      ></path>
      <path
        stroke="#7C7C7C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M362.377 327.687s-18.599-1.751-49.382 8.978m-200.026-10.679s18.486-1.878 45.84 6.214"
      ></path>
      <g
        stroke="#474444"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        opacity="0.36"
      >
        <path d="M171.574 365.284s-.069.382-.176 1.081" opacity="0.36"></path>
        <path
          strokeDasharray="4.69 4.69"
          d="M171.085 368.627c-1.062 8.018-3.014 28.479 2.844 39.479"
          opacity="0.36"
        ></path>
        <path
          d="M174.513 409.127c.201.328.408.645.622.945"
          opacity="0.36"
        ></path>
      </g>
      <path
        fill="#3C3C3B"
        d="M288.899 178.349c-17.268.679 2.732 100.965 3.272 121.807.54 20.841 14.983 37.19 32.251 36.512 17.275-.679 30.832-18.127 30.286-38.975-.534-20.842-48.534-120.022-65.809-119.344zm-104.452 0c17.268.679 16.857 127.374 16.317 148.215-.54 20.842-34.571 10.782-51.84 10.104-17.274-.679-30.832-18.127-30.285-38.975.54-20.842 48.54-120.022 65.808-119.344z"
      ></path>
      <path
        stroke="#7C7C7C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M329.665 285.529s-23.297 1.181-42.644 24.498m-140.477-26.999s22.983 3.996 39.373 29.474"
      ></path>
      <path
        fill="#6D3D2B"
        d="M272.466 172.066c2.512 0 20.088-6.566 23.234-17.593 6.279-21.991-15.071-53.408-15.071-53.408l-63.422 45.868 30.65 27.898s3.887-5.278 24.609-2.765z"
        opacity="0.3"
      ></path>
      <path
        fill="#FDDAC0"
        d="M278.092 88.248c-1.187 0-2.323.22-3.391.622 1.457-21.106-9.927-42.883-42.537-39.955-34.97 3.141-28.094 23.116-34.587 39.622a9.742 9.742 0 00-2.317-.29c-5.915 0-10.706 5.341-10.706 11.926 0 6.214 4.263 11.31 9.708 11.869 2.128 23.631 23.799 41.809 41.808 42.079 17.526.264 39.655-18.429 41.802-42.035.076 0 .145.013.22.013 5.909 0 10.7-5.335 10.7-11.926 0-6.59-4.791-11.925-10.7-11.925z"
      ></path>
      <path
        fill="#FAC354"
        d="M193.031 117.402a.941.941 0 00.942-.943v-7.539a.941.941 0 10-1.884 0v7.539c0 .522.427.943.942.943zm-3.139-8.797a.941.941 0 00.942-.942.941.941 0 10-.942.942zm-1.281-3.581c-.151-.101-.591.408-.591.408-.113.076-.251.107-.383.076 0 0-.602-.302-.703-.151-.1.151.408.591.408.591a.488.488 0 01.076.383s-.302.603-.151.704c.151.1.59-.409.59-.409a.488.488 0 01.383-.075s.603.301.703.151c.101-.151-.408-.591-.408-.591a.492.492 0 01-.075-.383c-.006 0 .295-.604.151-.704z"
      ></path>
      <path
        fill="#E8AF99"
        d="M193.553 105.527a.76.76 0 01-.201-.026c-2.82-.684-3.944-4.084-3.592-7.093.257-2.212 1.419-2.998 4.433-2.998h.188c.748.007.785.88.785 1.056 0 .239-.056 1.024-.766 1.024-.144 0-.314-.025-.49-.044a6.295 6.295 0 00-.791-.063c-1.149 0-1.557.698-1.701 1.282-.245 1.043-.139 2.105.295 2.997.464.962 1.274 1.64 2.21 1.866.144.032.578.139.521.861-.031.44-.301 1.138-.891 1.138zm86.995-.001c-.584 0-.86-.697-.892-1.137-.056-.722.383-.823.521-.861.936-.226 1.746-.904 2.211-1.866.433-.892.54-1.954.295-2.99-.138-.591-.546-1.282-1.702-1.282-.27 0-.546.031-.791.063-.176.018-.345.043-.484.043-.715 0-.772-.785-.772-1.024 0-.176.038-1.049.779-1.055h.188c3.014 0 4.17.785 4.427 2.997.352 3.01-.766 6.409-3.592 7.094-.056.006-.125.018-.188.018z"
      ></path>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M267.688 163.414a32.447 32.447 0 003.108-1.395c15.14-7.722 20.67-24.681 13.58-38.593-2.287-4.489-13.473-16.36-11.282-27.99"
      ></path>
      <path
        fill="url(#paint3_radial)"
        d="M238.318 38.032c-34.524 0-50.336 26.138-47.667 41.3 3.498 19.848-3.033 29.977-2.405 40.074.885 14.313 5.08-6.333 5.331-7.659 10.299-54.664 13.922-43.153 20.591-54.664 12.012 13.195 12.408-5.542 32.031-3.142 19.83 2.425 24.879 29.374 25.739 40.841.679 8.998 1.715 7.54 8.007 30.16 3.07 11.033-5.168-21.125-4.578-28.275.207-2.475 2.656-7.678 2.719-9.481.716-20.05-16.126-49.154-39.768-49.154z"
      ></path>
      <path
        fill="#FAC354"
        d="M279.688 117.402a.94.94 0 01-.942-.943v-7.539a.94.94 0 111.883 0v7.539a.94.94 0 01-.941.943zm3.139-8.797a.941.941 0 01-.942-.942.941.941 0 111.884 0 .941.941 0 01-.942.942zm1.287-3.581c.151-.101.591.408.591.408a.488.488 0 00.383.076s.603-.302.703-.151c.101.151-.408.591-.408.591-.075.113-.107.251-.075.383 0 0 .301.603.15.704-.15.1-.59-.409-.59-.409a.488.488 0 00-.383-.075s-.603.301-.703.151c-.101-.151.408-.591.408-.591.075-.113.107-.251.075-.383 0 0-.301-.604-.151-.704z"
      ></path>
      <path
        fill="#9297BE"
        d="M217.395 24.108c.019-.05-.037-.144-.081-.157l.006-.012c0-.006 0-.013.019-.013l.075-.019.17-.044c.113-.031.232-.062.351-.087l.71-.177c.471-.113.942-.2 1.413-.288.942-.17 1.884-.29 2.826-.378a35.422 35.422 0 012.819-.15c.942-.025 1.878.012 2.82.15 1.877.27 3.755.905 5.638 1.037 1.884.151 3.768-.025 5.658-.37.942-.164 1.884-.346 2.826-.46.942-.131 1.884-.181 2.826-.25 1.884-.132 3.767-.277 5.651-.208a49.13 49.13 0 015.652.54c.942.145 1.884.308 2.825.528.942.208 1.884.446 2.826.78a.2.2 0 01.126.25.224.224 0 01-.126.126 23.62 23.62 0 01-2.826.773c-.941.22-1.883.384-2.825.522a47.79 47.79 0 01-5.658.509c-1.884.056-3.774-.1-5.658-.258-.942-.082-1.884-.138-2.826-.283-.941-.125-1.883-.32-2.825-.502-1.884-.377-3.768-.585-5.652-.478-1.884.088-3.78.679-5.67.892-.948.107-1.89.12-2.832.057a30.847 30.847 0 01-2.832-.27 26.325 26.325 0 01-2.826-.54 20.3 20.3 0 01-1.413-.409c-.232-.082-.471-.17-.703-.264-.119-.044-.232-.1-.352-.157l-.182-.088-.1-.056-.063-.038-.038-.025c-.05-.025-.119-.126-.113-.189 0-.107.082-.188.189-.188.106 0 .175.088.175.194z"
      ></path>
      <path
        fill="#E8AF99"
        d="M235.738 108.53c-.792 1.596-1.457 3.487-1.413 5.297.044 1.746 1.212 3.644 3.165 3.556.508-.025.942-.415.942-.942 0-.491-.434-.968-.942-.943-.252.013-.321.013-.547-.126a1.556 1.556 0 01-.433-.427c-.308-.477-.326-1.074-.289-1.646.094-1.313.559-2.652 1.137-3.82.226-.459.119-1.018-.339-1.288-.409-.245-1.055-.12-1.281.339z"
      ></path>
      <path
        fill="#9297BE"
        d="M201.935 72.27c.451-.756-3.032-3.67-7.781-6.509-4.749-2.838-8.964-4.525-9.416-3.769-.452.757 3.032 3.671 7.78 6.51 4.749 2.838 8.965 4.525 9.417 3.769z"
      ></path>
      <path
        stroke="#FAC354"
        strokeMiterlimit="10"
        strokeWidth="1.122"
        d="M238.337 210.896c.295 0 .534-.886.534-1.979s-.239-1.979-.534-1.979c-.294 0-.533.886-.533 1.979s.239 1.979.533 1.979z"
      ></path>
      <path
        fill="#FAC354"
        d="M236.316 214.529c-.998 0-1.105-.742-1.111-.773a.636.636 0 01.113-.465.574.574 0 01.408-.239c.031-.006.056-.006.082-.006.288 0 .521.214.565.515a.66.66 0 01-.321.647c-.025.019-.044.051-.037.082a.079.079 0 00.063.063c.043.006.094.013.15.013.044 0 .088 0 .132-.007.276-.037.389-.195.345-.496l-.326-2.413-.075-.195a.083.083 0 00-.076-.05h-.019a.082.082 0 00-.056.088l.006.05a2.637 2.637 0 01-.22.025l-.05-.119.038-.05 2.254-.308.05.119c.007.025-.012.051-.037.051l-.27.037c-.044.006-.076.044-.069.088l.27 2.042c.05.39-.069 1.075-1.501 1.269a1.27 1.27 0 01-.308.032z"
      ></path>
      <path
        fill="#FAC354"
        d="M238.155 210.978v.013l-.238.031a.151.151 0 00-.132.176l.27 2.042c.088.635-.408 1.043-1.432 1.181a2.494 2.494 0 01-.301.019c-.923 0-1.03-.679-1.03-.71a.542.542 0 01.1-.408.51.51 0 01.352-.208c.025-.006.05-.006.075-.006.258 0 .452.182.484.446a.59.59 0 01-.283.572c-.056.031-.081.1-.075.163.006.063.063.113.125.126.051.006.107.012.164.012.05 0 .094-.006.144-.012.32-.044.459-.239.415-.584l-.327-2.413a.082.082 0 00-.013-.038l-.069-.17s0-.006-.006-.006c.578-.063 1.551-.201 1.777-.226zm-2.072.283v.019c-.038.006-.075.012-.1.012v-.019a.33.33 0 01.1-.012zm2.097-.446l-2.254.307a.148.148 0 00-.082.051.132.132 0 00-.025.094l.013.075c.006.063.063.107.125.107l.302-.038-.019-.125.069.169.327 2.413c.037.258-.044.377-.277.409-.037.006-.081.006-.119.006a.851.851 0 01-.138-.013.747.747 0 00.358-.728c-.044-.346-.308-.585-.641-.585-.031 0-.063 0-.094.006a.675.675 0 00-.458.271.727.727 0 00-.132.521c.006.038.125.842 1.187.842.1 0 .207-.006.32-.025 1.494-.201 1.626-.936 1.57-1.357l-.27-2.042.27-.038c.069-.013.119-.075.106-.138l-.012-.076c-.006-.031-.019-.062-.05-.081-.019-.019-.051-.025-.076-.025z"
      ></path>
      <path
        fill="#FAC354"
        d="M236.668 214.372a.337.337 0 00.094-.094c.075-.107.1-.251.075-.44l-.326-2.413c-.007-.037-.019-.119-.076-.176l1.332-.182a.291.291 0 00-.032.17l.27 2.004c.101.754-.659 1.031-1.337 1.131zm-.942-.1c-.346-.189-.39-.515-.396-.534a.485.485 0 01.415-.56c.025 0 .044-.006.069-.006.232 0 .408.164.439.409a.553.553 0 01-.383.578c-.069.025-.094.031-.125.075-.013.013-.019.025-.019.038z"
      ></path>
      <path
        fill="#FAC354"
        d="M237.653 211.173v.088l.27 2.004c.081.609-.484.88-1.068.999a.705.705 0 00.05-.427l-.326-2.413a.498.498 0 00-.025-.101l1.099-.15zm-1.84 2.073c.188 0 .333.138.364.339.031.208-.113.421-.339.497-.057.019-.094.031-.132.075a.661.661 0 01-.289-.433.425.425 0 01.076-.315.38.38 0 01.27-.157c.012-.006.031-.006.05-.006zm2.367-2.306l-2.242.302.013.075.283-.038c.018 0 .031-.006.044-.006.087 0 .131.044.144.157l.326 2.413c.044.327-.081.509-.383.547-.044.006-.094.006-.138.006-.182 0-.351-.05-.427-.132.019-.025.025-.025.088-.05a.631.631 0 00.44-.666c-.038-.283-.251-.472-.515-.472-.025 0-.05 0-.075.007a.561.561 0 00-.478.647c.025.17.214.735 1.061.735.095 0 .195-.006.302-.019 1.036-.138 1.545-.572 1.463-1.219l-.27-2.004c-.013-.088.038-.17.113-.176l.27-.038-.019-.069zm.623 3.187a.05.05 0 01-.025-.013l-.025-.107c-.006-.025.012-.05.037-.05l.264-.038c.044-.006.076-.044.069-.088l-.358-2.727c-.006-.037-.037-.069-.075-.069l-.295.007-.057-.12.038-.05 3.466-.471.226 1.413-.037.051-.082.012c-.188-.339-.364-.597-.553-.785-.006-.006-.018-.013-.025-.019 0-.006-.006-.019-.019-.025-.307-.289-.577-.321-.678-.321l-.332.044c-.044.007-.076.044-.07.088l.139 1.245c0 .025.012.043.037.056.013.006.025.013.044.013.006 0 .019 0 .025-.007.126-.037.22-.1.321-.213.238-.258.263-.553.282-.729l.119-.05c.007.006.019.006.032.012 0 .006.006.006.006.013l.264 1.922-.038.051-.088.012c-.15-.308-.22-.408-.358-.546-.169-.164-.276-.233-.508-.252h-.007c-.018 0-.044.007-.056.025-.019.019-.025.044-.019.063l.151 1.119c.006.037.037.069.075.069l.352-.013.056.12-.037.05c-.007 0-2.142.289-2.261.308z"
      ></path>
      <path
        fill="#FAC354"
        d="M241.797 210.481l.176 1.314h-.019a2.951 2.951 0 00-.521-.761c-.006-.006-.019-.012-.025-.018-.007-.007-.013-.019-.019-.026-.314-.295-.584-.345-.741-.345h-.006l-.327.038a.16.16 0 00-.138.175l.138 1.245a.168.168 0 00.069.113.15.15 0 00.088.025c.019 0 .032 0 .05-.007a.75.75 0 00.352-.232c.251-.264.283-.565.301-.754l.013-.006.251 1.841h-.019c-.106-.277-.182-.377-.326-.522-.176-.169-.302-.251-.559-.27h-.013a.16.16 0 00-.113.05.166.166 0 00-.044.132l.151 1.119a.162.162 0 00.157.138h.006l.321-.006v.018c-.252.032-1.947.264-2.186.296v-.019l.233-.031a.153.153 0 00.132-.176l-.358-2.727a.163.163 0 00-.157-.139h-.007l-.263.007v-.019c.339-.038 3.02-.402 3.403-.453zm.031-.163l-3.472.471a.143.143 0 00-.082.051.126.126 0 00-.025.094l.013.075c.006.063.063.107.125.107l.295-.006.358 2.727-.263.037c-.069.013-.12.076-.107.139l.012.075c.007.031.019.063.051.082.025.019.05.025.075.025l2.267-.308a.141.141 0 00.082-.05.133.133 0 00.025-.095l-.013-.075c-.006-.063-.063-.107-.125-.107l-.352.013-.151-1.119c.207.019.295.07.459.227.131.132.194.22.301.49.019.05.069.081.119.081l.088-.012c.032-.006.063-.019.082-.05a.13.13 0 00.025-.095l-.257-1.904a.076.076 0 00-.019-.05c.157.164.333.396.496.735a.119.119 0 00.113.069l.088-.012a.139.139 0 00.081-.05.125.125 0 00.025-.095l-.188-1.376a.13.13 0 00-.126-.094zm-1.343 1.766l-.138-1.244.326-.038c.094 0 .333.031.622.302a.118.118 0 00-.063-.019l-.088.012a.128.128 0 00-.107.113c-.019.176-.044.446-.263.685a.6.6 0 01-.289.189z"
      ></path>
      <path
        fill="#FAC354"
        d="M239.223 213.944a.246.246 0 00.025-.151l-.364-2.708a.246.246 0 00-.07-.151l1.319-.182a.277.277 0 00-.019.151l.182 1.35c0 .019.013.032.019.044-.006.013-.006.026-.006.038l.176 1.269c.012.069.037.12.069.157l-1.331.183zm2.147-1.106a1.325 1.325 0 00-.232-.302c-.182-.182-.308-.251-.528-.276a.825.825 0 00.308-.226c.157-.164.232-.346.27-.503l.182 1.307zm.534-1.257c-.352-.622-.722-.854-.973-.936l.829-.113.144 1.049z"
      ></path>
      <path
        fill="#FAC354"
        d="M241.691 210.62l.081.616c-.194-.277-.389-.447-.565-.547l.484-.069zm-1.658.226c0 .019 0 .044.006.069l.182 1.351c0 .019.006.031.013.05v.032l.176 1.269.018.082-1.099.15v-.069l-.37-2.708c-.006-.025-.013-.056-.019-.075l1.093-.151zm1.118.993l.094.697a2.984 2.984 0 00-.057-.056.953.953 0 00-.364-.252.99.99 0 00.151-.138c.075-.082.132-.163.176-.251zm.678-1.395l-3.454.471.013.076.276-.038h.012c.063 0 .113.05.126.144l.364 2.708c.013.088-.031.151-.113.164l-.264.037.013.076 2.248-.308-.013-.075-.339.044h-.018c-.063 0-.113-.057-.126-.145l-.176-1.269c.346.006.471.05.691.27.151.151.22.258.326.528l.076-.013-.258-1.904-.069.013c-.019.195-.05.496-.295.76a.784.784 0 01-.49.264l-.182-1.351c-.012-.094.013-.157.138-.176l.264-.037c.025-.007.05-.007.075-.007.333 0 .842.271 1.275 1.15l.069-.012-.169-1.37z"
      ></path>
      <path
        fill="#EE7B72"
        d="M269.391 117.95c5.386-.433 9.623-2.395 9.463-4.38-.159-1.985-4.655-3.244-10.041-2.81-5.386.434-9.623 2.395-9.463 4.38.16 1.986 4.655 3.244 10.041 2.81zm-57.89-2.815c.16-1.986-4.077-3.947-9.463-4.381-5.386-.433-9.881.825-10.041 2.81-.16 1.986 4.077 3.947 9.463 4.381 5.386.433 9.882-.825 10.041-2.81z"
        opacity="0.17"
      ></path>
      <path
        stroke="#777"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M96.585 365.661s-8.433 15.746-2.781 24.869"
      ></path>
      <path
        fill="#E8AF99"
        d="M223.191 126.135c9.112 3.406 19.422 3.305 28.458-.295 1.112-.446.628-2.268-.502-1.816-8.766 3.487-18.618 3.601-27.46.296-1.13-.428-1.626 1.394-.496 1.815z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        d="M321.809 344.232c0 5.856-4.471 10.606-9.984 10.606H169.784c-5.513 0-9.984-4.75-9.984-10.606v-76.084c0-5.856 4.471-10.606 9.984-10.606h142.041c5.513 0 9.984 4.75 9.984 10.606v76.084z"
      ></path>
      <path
        fill="#403A37"
        d="M245.915 292.809h.197c.158 1.951-.587 3.41-1.493 4.466-.888 1.048-2.104 2.066-4.072 1.911-.131-1.923.615-3.274 1.519-4.327.839-.982 2.376-1.856 3.849-2.05zm5.956 20.313v.055c-.553 1.675-1.342 3.11-2.304 4.442-.879 1.209-1.956 2.836-3.878 2.836-1.661 0-2.765-1.068-4.467-1.097-1.801-.03-2.792.893-4.438 1.125h-.562c-1.209-.175-2.185-1.133-2.895-1.996-2.097-2.549-3.717-5.842-4.018-10.057v-1.238c.128-3.016 1.593-5.468 3.541-6.657 1.028-.632 2.442-1.17 4.015-.929.675.104 1.364.335 1.968.563.572.22 1.288.61 1.966.59.459-.014.916-.253 1.379-.422 1.356-.49 2.686-1.051 4.438-.787 2.106.318 3.601 1.254 4.524 2.697-1.781 1.134-3.19 2.843-2.949 5.76.214 2.651 1.755 4.201 3.68 5.115z"
        opacity="0.6"
      ></path>
      <path
        fill="#C59B6C"
        stroke="#C08A3C"
        strokeMiterlimit="10"
        strokeWidth="1.122"
        d="M195.826 89.73h-2.832a.898.898 0 010-1.797h2.832a.898.898 0 010 1.797zm83.278 0h-2.833a.897.897 0 01-.897-.898c0-.496.401-.898.897-.898h2.833a.898.898 0 010 1.797z"
      ></path>
      <path
        stroke="#C08A3C"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="1.122"
        d="M230.764 102.266v-.145c0-1.609 1.093-2.915 2.449-2.915h6.405c1.35 0 2.449 1.306 2.449 2.915v.145"
      ></path>
      <path
        fill="#F5F4CC"
        d="M231.254 96.604c0 11.492-8.012 20.169-17.903 20.169-9.883 0-17.902-8.677-17.902-20.169s8.32-14.388 18.204-14.388 17.601 6.346 17.601 14.388zm10.317 0c0 11.492 8.013 20.169 17.903 20.169 9.89 0 17.903-8.677 17.903-20.169s-8.327-14.388-18.211-14.388c-9.884 0-17.595 6.346-17.595 14.388z"
        opacity="0.16"
      ></path>
      <path
        stroke="#C08A3C"
        strokeMiterlimit="10"
        strokeWidth="1.683"
        d="M231.254 98.849c0 11.492-8.012 20.169-17.903 20.169-9.883 0-17.902-8.677-17.902-20.17 0-11.491 8.32-14.388 18.204-14.388s17.601 6.346 17.601 14.389zm10.317 0c0 11.492 8.013 20.169 17.903 20.169 9.89 0 17.903-8.677 17.903-20.17 0-11.491-8.327-14.388-18.211-14.388-9.884 0-17.595 6.346-17.595 14.389z"
      ></path>
      {/*EYES*/}
      <g className="eyes" fill="#5A2B1A">
        <ellipse cx="256" cy="100" rx="4" ry="4"></ellipse>
        <ellipse cx="217" cy="100" rx="4" ry="4"></ellipse>
      </g>
      <path
        stroke="#5A2B1A"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2.245"
        d="M195.543 81.933c0-.509 7.027-2.23 15.699-2.23 8.671 0 15.698 1.721 15.698 2.23m50.863 0c0-.509-7.026-2.23-15.698-2.23s-15.699 1.721-15.699 2.23"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(58.1824 0 0 42.8799 244.923 41.14)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#5A2B1A"></stop>
          <stop offset="0.472" stopColor="#623221"></stop>
          <stop offset="0.679" stopColor="#794435"></stop>
          <stop offset="0.714" stopColor="#7D4839"></stop>
          <stop offset="0.997" stopColor="#BB7A25"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(189.427 0 0 189.542 234.482 14.405)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#5A2B1A"></stop>
          <stop offset="0.472" stopColor="#623221"></stop>
          <stop offset="0.679" stopColor="#794435"></stop>
          <stop offset="0.714" stopColor="#7D4839"></stop>
          <stop offset="0.997" stopColor="#BB7A25"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(132.826 0 0 145.906 231.472 24.317)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.34" stopColor="#5A2B1A"></stop>
          <stop offset="0.472" stopColor="#623221"></stop>
          <stop offset="0.679" stopColor="#794435"></stop>
          <stop offset="0.714" stopColor="#7D4839"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear"
          x1="239.186"
          x2="239.186"
          y1="300.984"
          y2="168.783"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.252" stopColor="#8B7BB3"></stop>
          <stop offset="1" stopColor="#D5C7C7"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="239.416"
          x2="243.144"
          y1="259.32"
          y2="384.752"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.184" stopColor="#CCCBCB"></stop>
          <stop offset="0.75" stopColor="#989898"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Me;