import * as React from "react"
import { useEffect } from 'react';
import Me from '../components/me'
import Layout from '../components/layout'
import gsap from "gsap";
import TextPlugin from "gsap/TextPlugin";

// styles
import "../styles/global.css";
import "../styles/home.css";
import "@fontsource/sora/300.css";
import "@fontsource/sora/500.css";
import "@fontsource/sora/400.css";
import "@fontsource/sora/600.css";
import "@fontsource/sora";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import "@fontsource/caveat";



// markup
const IndexPage = () => {
  
  // Animations 

  useEffect(()=> {
    gsap.registerPlugin(TextPlugin);

    gsap.defaults({ease: "none"});

    const tl = gsap.timeline({repeat: 2, delay: 2});
    
    tl.to(".teamWord", {duration: 1, text:"   "}, "+=4")
    tl.to(".teamWord", {duration: 2, text:"badass"})
    tl.to(".teamWord", {duration: 1, text:"  "}, "+=4")
    tl.to(".teamWord", {duration: 2, text:"bold"})
    tl.to(".teamWord", {duration: 1, text:"   "}, "+=4")
    tl.to(".teamWord", {duration: 2, text:"design"})
    tl.to(".teamWord", {duration: 1, text:"  "}, "+=4")
    tl.to(".teamWord", {duration: 2, text:"dream"})
  }, []);

  return (
    <Layout pageTitle="home"><main>
      <div className="block">

        <div className="textBlock"> 
          <h1>Mayte<br/>Valdez</h1>
          <p className="titleUX">Product Designer</p>
          <p> Currently based in France, working at <a className="link" href="https://www.ibm.com/fr-fr" rel="noreferrer" target="_blank">IBM</a>.</p>
          <p>Passionate about humanizing tech experiences and creating thoughtful products.</p>
          
         {/*span className="teamWord">dream</span> team. <--texte dynamique*/} 
          <div className="flexH" >
              <a className="link" href="https://www.linkedin.com/in/valdezmayte/" rel="noreferrer" target="_blank">Linkedin</a>
              <p>|</p>
              <a className="link" href="https://www.behance.net/may-valdez" rel="noreferrer" target="_blank">Behance</a>
              <p>|</p>
              <a className="link" href="https://dribbble.com/may_vdz" rel="noreferrer" target="_blank">Dribble</a> 
              <p>|</p>
              <a className="link" href="https://github.com/mayte-valdez" rel="noreferrer" target="_blank">Github</a>
          </div>
          
        </div>
        <div className="avatarBlock">
          <Me/>
        </div>
        
      </div>
      
      <div className="homeBkg"></div>
      
    </main></Layout>
  )
}

export default IndexPage
